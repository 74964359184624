.methodDialogHeader {
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 400;


}


.modal-header {
    margin-bottom: unset !important;

    >.modal-close-btn {
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            align-items: center;
            color: #1f2747;
            display: flex;
            font-size: 16px !important;
            font-weight: 400;
            height: 16px !important;
            line-height: 16px !important;
            text-align: center;
            width: 10px !important;
        }

        &:hover {
            background-color: #f3f4f5;
            border-radius: 8px;
        }
    }
}


.modal-body {

    .nav-link {
        color: var(--userful-doc-primary);
    }

    .methodDescription {
        padding: 15px;
        border-radius: 5px;
        background: var(--userful-bg-primary-light);
        margin-bottom: 15px;
    }

    .tab-content {
        border: solid 1px var(--userful-border-secondary);
        border-top: none;
        padding: 10px;

        .responseContainer {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .singleMediaType {
            .typeHeading {
                display: flex;
                gap: 10px;
                margin-bottom: 10px;
            }

            hr {
                margin-top: 4px;
                margin-bottom: 4px;
            }

        }

        .lable {
            font-weight: 600;



            a {
                color: var(--userful-doc-primary);
            }

        }

        .schemaDiv {
            gap: 10px;
            display: flex;
            flex-direction: column;
            padding-left: 10px;

            .schemaContainer {
                pre {
                    background: var(--userful-secondary);
                    padding: 10px;
                    margin-bottom: unset !important;
                    border-radius: 4px;
                }

                .lable {
                    text-transform: capitalize;
                }
            }
        }
    }
}

.modal-dialog {
    max-width: 1000px;

    .modal-content {
        max-width: 1000px;
    }
}