.main {
    display: flex;
    height: calc(100vh - 56px);

    .flex {
        align-items: center;
        display: flex;
    }

    .contentContainer {
        display: flex;
        flex: 1;
        padding: 32px;
        overflow-y: auto;
        flex-direction: column;
    }
}

.apiNav {
    position: fixed;
    right: 32px;
    top: 88px;

    .nav-link {
        color: #4b5168;
        margin-bottom: 4px;

        &:hover {
            color: var(--userful-primary-dark);
            background: var(--userful-secondary);
            border-radius: 8px;
        }
    }
}

html,
body {
    font-family: Inter, sans-serif !important;
    height: 100%;
    width: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: varu(--userful-primary-light);
}

.App {
    height: 100%;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

h1 {
    font-size: 32px !important;
}

h2 {
    font-size: 24px !important;
}

h3{
    font-size: 18px !important;
}

p {
    margin-bottom: 24px !important;
  }