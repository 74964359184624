.apiTopContainer {
  display: flex;
  justify-content: center;

  .apiContainer {
    height: 100%;
    width: 800px;

    .title {
      align-items: baseline;
      margin-bottom: 36px;

      .version {
        font-size: 65%;
        color: var(--userful-gray-dark);
      }
    }

    .apiDiv {
      display: flex;
      flex-direction: row;
      gap: 15px;
      flex-wrap: wrap;
      margin-bottom: 36px;
      align-items: baseline;
      font-weight: 500;
      line-height: 1.2;
      font-size: 32px;

      .version {
        font-size: 65%;
        color: var(--userful-gray-dark);
      }

    }


    .descriptionContainer {
      margin-bottom: 24px;
      text-align: left;

      h3 {
        color: #1f2747;
      }

      .description {
        span {
          background: var(--userful-bg-secondary-hover);
          padding: 0px 4px;
          border-radius: 4px;
        }

        a {
          color: var(--userful-doc-primary);
        }
      }

    }

    .apiListContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .apiCatagoryContainer {
        border: solid 1px var(--userful-border-secondary);
        border-radius: 8px;
        overflow: hidden;

        .catagoryTitle {
          padding: 10px;
          background: var(--userful-secondary);

        }

        .catagoryList {
          padding: 10px;
        }

        .apiPath {
          display: flex;
          flex-direction: column;
          // border-bottom: solid 1px var(--userful-border-secondary);

          &:last-child {
            border-bottom: none;
          }

          .pathContainer {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            height: 37px;
            padding: 10px 15px;
          }

          .methodHorizonalList {
            display: flex;
            gap: 10px;
          }

          .methodVerticalList {
            flex-direction: column;
            border: solid 1px var(--userful-border-secondary);
            border-radius: 5px;

            &.show {
              display: flex;
            }

            .methodWithDescription {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 10px;
              border-bottom: solid 1px var(--userful-border-secondary);
              padding: 10px;

              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

.noteBlock {
  background: var(--userful-bg-secondary);
  padding: 15px;
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  border-radius: 4px;
  white-space: pre-wrap;
  margin-bottom: 16px;
  svg {
    width: 16px;
    height: 16px;
    margin: 4px;
  }

  >div {}
}

.methodIcon {
  height: 25px;
  width: 70px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-transform: uppercase;

}

.interactable {
  cursor: pointer;
}