.codeBlock {
  width: 100%;
  display: flex;
  background-color: var(--userful-bg-secondary);
  border-radius: 4px;
  > div {
    width: calc(100% - 32px);
    white-space: pre-wrap;
    line-height: 30px;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 12px;
  }
  .lineNumbers {
    width: 30px;
    > div {
      line-height: 30px;

    }
  }
}